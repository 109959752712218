import './util/monitoring';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import { createApp } from 'vue';
import './assets/css/base.scss';
import App from './App.vue';
import './middleware/auth.guard';
import './middleware/transition';
import { router } from './routes';
import { pinia } from './stores/pinia';
import { i18n } from './util/i18n';
import { initHammer } from './util/hammer';
import { Capacitor } from '@capacitor/core';
import Particles from 'vue3-particles';
import { initClickout } from './util/clickout';
import { initIntercept, applyCompressedString } from '@bluepic/privacy';

import posthog from 'posthog-js';

// @ts-ignore
window.__STARTUP_TIME__ = Date.now();

initIntercept(import.meta.env.V_CONSENT_LS_KEY);
const currentURL = new URL(location.href);
if (currentURL.searchParams.has('consent')) {
  applyCompressedString(currentURL.searchParams.get('consent')!, import.meta.env.V_CONSENT_LS_KEY);
}

const env = new Map<string, string>();
env.set('PLATFORM', Capacitor.getPlatform());
env.set('VERSION', APP_VERSION);
const omitInProd: (keyof ImportMetaEnv)[] = [
  'V_STRIPE_PUBLIC_KEY',
  'V_SOCIAL_PRO_MONTHLY_PRICE_ID',
  'V_SOCIAL_PRO_YEARLY_PRICE_ID',
  'V_SOCIAL_PRO_PRODUCT_ID',
  'SSR',
  'DEV',
  'PROD',
];
for (const key in import.meta.env) {
  if (import.meta.env.PROD && omitInProd.includes(key as keyof ImportMetaEnv)) {
    continue;
  }
  env.set(key, (import.meta.env as any)[key]);
}
// @ts-ignore
window.env = Object.fromEntries(env);

export const app = createApp(App);

app.use(Particles as any);
app.use({
  install(app) {
    app.config.globalProperties.$posthog = posthog.init('phc_N5wkwtrdqnadaxsOpYGtxgQJDvEGbV9QmPr5O3pkNkQ', {
      api_host: 'https://eu.i.posthog.com',
    });
  },
});
app.use(router);
app.use(i18n);
app.use(pinia);
initHammer(app);

initClickout(app);

app.mount('#app');
